(() => {
  const BWDHero = () => {
    const selectors = {
      component: ".bwd-hero",
      main: "main",
      header: "header",
    };

    const classes = {
      headerCollapse: "c-container-collapsed",
      editMode: "edit",
    };

    const init = () => {
      const heroComponents = Array.from(document.querySelectorAll(selectors.component));
      heroComponents.forEach((hero) => {
        if (document.querySelector(selectors.main).firstElementChild.firstElementChild == hero && !document.body.classList.contains(classes.editMode)) {
          const header = document.querySelector(selectors.header);
          header.classList.add(classes.headerCollapse);
        }
      });
    };

    const searchText = () => {
      const $body = document.body;
      let name1;
      let name2;
      let dateParam;
      let hotelParam;
      let couponParam;

      const searchParams = ["[NAME1]", "[NAME2]", "[HOTEL]", "[DATE]", "[COUPON]"];

      const currentUrl = window.location.href;
      const urlObj = new URL(currentUrl);
      const queryParams = new URLSearchParams(urlObj.search);

      queryParams.forEach((value, key) => {
        switch (key) {
          case "name":
            if (value) {
              const names = value.split(",");
              name1 = names[0];
              name2 = names[1];
            }
            break;

          case "date":
            if (value) {
              const locale = document.documentElement.lang;
              const normalizedDate = `${value}T12:00Z`;
              const date = new Date(normalizedDate);

              const dateFormatter = new Intl.DateTimeFormat(locale, {
                year: "numeric",
                month: "long",
                day: "numeric",
                timeZone: "UTC",
              });

              dateParam = dateFormatter.format(date);
            }
            break;

          case "hotel":
            if (value) {
              hotelParam = value;
            }
            break;

          case "coupon":
            if (value) {
              couponParam = value;
            }
            break;

          default:
            break;
        }
      });

      const dataParams = [name1, name2, dateParam, hotelParam, couponParam];

      searchParams.forEach((param) => {
        if (param != "") {
          const iter = document.createNodeIterator($body, NodeFilter.SHOW_TEXT);
          let actual;
          const pos = $body.innerText.search(param);
          if (pos >= 0) {
            while ((actual = iter.nextNode())) {
              switch (param) {
                case "[NAME1]":
                  if (dataParams[0]) {
                    actual.data = actual.data.replaceAll(param, dataParams[0]);
                  }
                  break;
                case "[NAME2]":
                  if (dataParams[1]) {
                    actual.data = actual.data.replaceAll(param, dataParams[1]);
                  }
                  break;
                case "[DATE]":
                  if (dataParams[2]) {
                    actual.data = actual.data.replaceAll(param, dataParams[2]);
                  }
                  break;
                case "[HOTEL]":
                  if (dataParams[3]) {
                    actual.data = actual.data.replaceAll(param, dataParams[3]);
                  }
                  break;
                case "[COUPON]":
                  if (dataParams[4]) {
                    actual.data = actual.data.replaceAll(param, dataParams[4]);
                  } else {
                    if (!bcl.editMode) {
                      const bannerDiscount = $body?.querySelector(".bwd-discount-banner");
                      bannerDiscount?.classList.add("hidden");
                    }
                  }
                  break;
                default:
                  break;
              }
            }
          }
        }
      });
    };

    init();
    searchText();
  };

  document.addEventListener("DOMContentLoaded", BWDHero);
})();
